import React, { createRef } from 'react';

//All the svg files
import iosInnerHeight from 'ios-inner-height';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Image from 'Demora/image';

import { withRouter } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

const div = createRef();

const defaultTop = 60;

class Sidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			click: 0,
			iIH: iosInnerHeight(),
			height: 70,
			profileClick: 0,
			scrollY: 0,
			top: defaultTop,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleOnResize);
		window.addEventListener('scroll', this.handleOnScroll);

		div.current.style.top = this.state.top + 'px';

		const dc = div.current.getBoundingClientRect();

		this.setState({
			height: dc.height,
			top: dc.top,
		});

		this.checkPage();

		this.unlisten = this.props.history.listen((location, action) => {
			this.checkPage();
		});

		setTimeout(this.handleOnScroll, 10);
	}

	componentWillUnmount() {
		if (this.unlisten) {
			this.unlisten();
		}
	}

	checkPage = () => {
		const pathIsEarn = !!location.pathname.match(
			/^\/home\/promote\/earnCredits/
		);
		const top = pathIsEarn ? 40 : defaultTop;
		const delay = pathIsEarn ? 1300 : 200;

		setTimeout(() => {
			div.current.style.top = top + 'px';
			this.setState({
				top,
			});
		}, delay);
	};

	handleClick = () => {
		const click = this.state.click;
		this.setState({
			click: click ? 0 : 1,
			profileClick: 0,
		});
	};

	handleProfileClick = () => {
		const profileClick = this.state.profileClick;
		this.setState({
			click: window.innerWidth < 768 ? 1 : 0,
			profileClick: profileClick ? 0 : 1,
		});
	};

	handleOnResize = () => {
		div.current.style.top = this.state.top + 'px';
		setTimeout(this.handleOnScroll, 300);
	};

	handleOnScroll = () => {
		const { iIH, height, scrollY, top } = this.state;

		const st = {
			scrollY: window.scrollY,
		};

		let Height = height;

		if (height != div.current.getBoundingClientRect().height) {
			Height = div.current.getBoundingClientRect().height;
			st.height = div.current.getBoundingClientRect().height;
		}

		let iih = iIH;

		if (iIH != iosInnerHeight()) {
			iih = iosInnerHeight();
			st.iIH = iih;
		}

		const h = -Height + iih;

		const scrollDiff = scrollY - st.scrollY;

		let newTop = parseInt(div.current.style.top || top, 10) + scrollDiff;

		if (newTop > top && scrollDiff >= 0) {
			// scroll up
			newTop = top;
		}

		if (h > newTop && scrollDiff < 0) {
			// scroll down
			newTop = top + height > iih ? -(height - iih) : top;
		}

		div.current.style.top = newTop + 'px';

		this.setState(st);

		document.getElementById('pageContent').style.minHeight =
			top + height + 'px';
	};

	setClick = (v) => {
		this.setState({
			click: v,
		});
	};

	render() {
		const { click, iIH, profileClick, top, height } = this.state;

		const { user } = this.props;

		return (
			<div>
				<Overlay clicked={click} onClick={() => this.handleClick()} />
				<Container ref={div} style={{ left: 0 }}>
					<Button clicked={click} onClick={() => this.handleClick()}>
						Click
					</Button>
					<SidebarContainer clicked={click}>
						<Logo></Logo>
						<SlickBar clicked={click}>
							<Item
								onClick={() => this.setClick(0)}
								exact
								activeClassName='active'
								to='/home'
							>
								<i className='fa-fw fas fa-tachometer-alt'></i>
								<Text clicked={click}>Dashboard</Text>
							</Item>
							<Item
								onClick={() => this.setClick(0)}
								activeClassName='active'
								to='/home/promote/clikPost'
							>
								<i className='fas fa-bullhorn'></i>
								<Text clicked={click}>Campaigns</Text>
							</Item>
							<Item
								onClick={() => this.setClick(0)}
								activeClassName='active'
								to='/home/promote/earnCredits'
							>
								<i className='fas fa-bullseye'></i>
								<Text clicked={click}>Earn&nbsp;Credits</Text>
							</Item>
							<Item
								onClick={() => this.setClick(0)}
								activeClassName='active'
								to='/home/stats/detailed'
							>
								<i className='fas fa-poll'></i>
								<Text clicked={click}>Detailed&nbsp;Stats</Text>
							</Item>
							<Item
								onClick={() => this.setClick(0)}
								activeClassName='active'
								to='/settings'
							>
								<i className='fa-fw fa fa-cogs'></i>
								<Text clicked={click}>Settings</Text>
							</Item>
						</SlickBar>

						<Profile clicked={profileClick}>
							<img
								onClick={() => this.handleProfileClick()}
								src={Image(user.image, 50)}
								alt='Profile'
							/>
							<Details clicked={profileClick}>
								<Name>
									<h5 className='text-truncate'>
										{user.first_name}
									</h5>
									<a href='/#'>view&nbsp;profile</a>
								</Name>

								<Logout>
									<a href='/logout'>
										<i className='fas fa-fw fa-sign-out-alt text-white'></i>
									</a>
								</Logout>
							</Details>
						</Profile>
					</SidebarContainer>
					{top + height > iIH && (
						<Button
							clicked={click}
							onClick={() => this.handleClick()}
							style={{ margin: '0.5rem 0 0.5rem 0.5rem' }}
						>
							Click
						</Button>
					)}
				</Container>
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(Sidebar, {
		user: 'user',
	})
);

const Overlay = styled.div`
	position: fixed;

	display: ${(props) => (props.clicked ? 'block' : 'none')};

	z-index: 4999;

	background-color: #ac0101;

	height: 100vh;
	width: 100vw;

	top: 0;
	left: 0;

	background-color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
	position: fixed;

	z-index: 5000;

	transition: all 1s;
	transition-timing-function: ease;

	.active {
		border-right: 4px solid var(--menu-white);

		img {
			filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
				brightness(103%) contrast(103%);
		}
	}
`;

const Button = styled.button`
	background-color: var(--menu-black);
	border: none;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	margin: 0 0 0 0.5rem;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	&::before,
	&::after {
		content: '';
		background-color: var(--menu-white);
		height: 2px;
		width: 1rem;
		position: absolute;
		transition: all 0.3s ease;
	}

	&::before {
		top: ${(props) => (props.clicked ? '1.5' : '1rem')};
		transform: ${(props) =>
			props.clicked ? 'rotate(135deg)' : 'rotate(0)'};
	}

	&::after {
		top: ${(props) => (props.clicked ? '1.2' : '1.5rem')};
		transform: ${(props) =>
			props.clicked ? 'rotate(-135deg)' : 'rotate(0)'};
	}
`;

const SidebarContainer = styled.div`
	background-color: var(--menu-black);
	width: 3.5rem;
	min-height: 70vh;
	margin-top: 1rem;
	border-radius: 0 30px 30px 0;
	padding: 1rem 0;

	display: flex;
	flex-direction: column;
	/*  align-items: center; */
	justify-content: space-between;

	position: relative;

	@media (max-width: 767px) {
		width: ${(props) => (props.clicked ? '3.5rem' : 0)};
		overflow: ${(props) => (props.clicked ? 'initial' : 'hidden')};
	}

	transition: all 0.5s ease;
`;

const Logo = styled.div`
	width: 2rem;

	img {
		width: 100%;
		height: auto;
	}
`;

const SlickBar = styled.ul`
	color: var(--menu-white);
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--menu-black);

	padding: 0rem 0;

	position: relative;
	/*  top: 6rem; */
	/*  left: ${(props) => (props.clicked ? '100%' : 9)}; */

	width: ${(props) => (props.clicked ? '12rem' : '3.5rem')};
	transition: all 0.5s ease;
	border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
	text-decoration: none;
	color: var(--menu-white);
	width: 100%;
	padding: 1rem 0;
	cursor: pointer;

	display: flex;
	padding-left: 0.5rem;

	&:hover {
		border-right: 4px solid var(--menu-white);

		img {
			filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
				brightness(103%) contrast(103%);
		}
	}

	img {
		width: 1.2rem;
		height: auto;
		filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
			brightness(78%) contrast(85%);
	}

	i {
		font-size: 25px;
		color: rgb(119, 119, 119);
		width: 1.2rem;
		height: auto;
		filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
			brightness(78%) contrast(85%);
	}
`;

const Text = styled.span`
	width: ${(props) => (props.clicked ? '100%' : '0')};
	overflow: hidden;
	margin-left: ${(props) => (props.clicked ? '1.5rem' : '0')};
	transition: all 0.3s ease;
`;

const Profile = styled.div`
	width: ${(props) => (props.clicked ? '14rem' : '3rem')};
	height: 3rem;

	padding: 0.5rem 1rem;
	/* border: 2px solid var(--menu-white); */
	border-radius: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
	/*  margin-left: ${(props) => (props.clicked ? '9rem' : '0')}; */

	background-color: var(--menu-black);
	color: var(--menu-white);

	transition: all 0.3s ease;

	img {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		cursor: pointer;

		&:hover {
			border: 2px solid var(--menu-grey);
			padding: 2px;
		}
	}
`;

const Details = styled.div`
	display: ${(props) => (props.clicked ? 'flex' : 'none')};
	width: 11.5rem;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.div`
	padding: 0 1.5rem;
	margin: 0 0.5rem;
	width: 8.5rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h5 {
		display: inline-block;
		margin-bottom: 0;
		max-width: 8.5rem;
	}

	a {
		font-size: 0.8rem;
		text-decoration: none;
		color: var(--menu-grey);

		&:hover {
			text-decoration: underline;
		}
	}
`;

const Logout = styled.button`
	border: none;
	width: 2rem;
	height: 2rem;
	background-color: transparent;

	img {
		width: 100%;
		height: auto;
		filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg)
			brightness(100%) contrast(126%);
		transition: all 0.3s ease;
		&:hover {
			border: none;
			padding: 0;
			opacity: 0.5;
		}
	}
`;
