import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

import RecordFeedActivity from '../../js/utils/recordFeedActivity';

import Sidebar from './Sidebar';

import * as Sentry from '@sentry/react';

import z5Stats from 'resources/../vendor/z5internet/z5-stats/src/resources/assets/js/stats';

class PreHeader extends Component {
	componentDidMount() {
		const user = this.props.user;

		this.z5Stats = new z5Stats(user.id);

		this.props.history.listen((location, action) => {
			this.z5Stats.changePage();
		});

		this.setSentryUser(user);
	}

	componentDidUpdate(prevProps) {
		const user = this.props.user;
		if (user.id !== prevProps.user.id) {
			this.z5Stats.setUserID(user.id);

			this.setSentryUser(user);
		}
	}

	setSentryUser = (user) => {
		Sentry.setUser({
			id: user.id,
			username: user.first_name,
			email: user.email,
		});
	};

	render() {
		const props = this.props;

		return (
			<div>
				{props.user.id && <Sidebar />}
				<RecordFeedActivity />
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(PreHeader, {
		user: 'user',
	})
);
