export default function (img, size) {
	if (!img.u) {
		if (img.match && img.match(/^http/)) {
			return img;
		}
		return '/assets/blank' + size + '.png';
	}

	return (
		(img.p ? img.p : '') + '' + size + '-' + img.u + '-' + img.m + '.' + img.e
	);
}
