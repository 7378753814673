import React, { Component } from 'react';

import { NavbarBrand } from 'reactstrap';

import logo from '../../images/logo1.png';

class TopNavBarLogo extends Component {
	render() {
		return (
			<NavbarBrand>
				<img src={logo} style={{ height: '35px' }} />
			</NavbarBrand>
		);
	}
}

export default TopNavBarLogo;
