import asyncComponent from 'Demora/AsyncComponent';

import SocialRoutes from 'resources/../vendor/z5internet/react-social/src/resources/assets/js/src/app/Auth/Routes';

const AuthHome2 = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-authHome" */ '../../js/components/dashboard'
		)
);

export default SocialRoutes.concat([
	{
		path: '/home/:tab/:tab1?',
		component: AuthHome2,
	},
]);
