import React from 'react';

import * as Sentry from '@sentry/react';

if (!('_self' in React.createElement('div'))) {
	Sentry.init({
		dsn: 'https://d2af3e62014f0fdaf3cb0ba11ebe8903@o274598.ingest.us.sentry.io/4508273057136640',
		ignoreErrors: [
			'ResizeObserver',
			'Load failed',
			'Could not connect to the server.',

			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			"Can't find variable: ZiteReader",
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
			// Generic error code from errors outside the security sandbox
			// You can delete this if using raven.js > 1.0, which ignores these automatically.
			'Script error.',
			// Avast extension error
			'_avast_submit',

			'Failed to fetch',
			'NetworkError when attempting to fetch resource',
			'Blocked a frame with origin',
			'The operation was aborted',
			'Unexpected end of input',
			'The Internet connection appears to be offline',
			'ResizeObserver',
			// 'ResizeObserver loop completed with undelivered notifications',
			// 'ResizeObserver loop limit exceeded',
			// 'Origin null is not allowed by Access-Control-Allow-Origin',
			// 'The network connection was lost.',
			// 'Permission denied to access property "onbeforeunload" on',
			// 'Network request failed',
			// 'Cannot redefine property: hidden',
			// 'Unexpected EOF',

			'Abgebrochen',
			'avbruten',
			'avbrutt',
			'cannot parse response',
			'geannuleerd',
			'Interruption de la connexion',
			'Netzwerkverbindung',
			'Type error',
			'Unexpected EOF',
			'Unexpected end of script',
			'Unexpected identifier',
			'Unexpected token',
		],
		beforeSend(event, hint) {
			const error = hint.originalException;

			if (
				navigator.userAgent.match(/rv:11.0/) ||
				navigator.userAgent.match(/MSIE 10.0/)
			) {
				return null;
			}

			if (error && error.message) {
				let ms = [
					'NetworkError when attempting to fetch resource',
					'Failed to fetch',
					'Blocked a frame with origin',
					'ResizeObserver',
					'ResizeObserver loop completed with undelivered notifications',
					'ResizeObserver loop limit exceeded',
					'Origin null is not allowed by Access-Control-Allow-Origin',
					'Loading chunk',
					'Loading CSS chunk',
					'The network connection was lost.',
					'Permission denied to access property "onbeforeunload" on',
					'Network request failed',
					'Cannot redefine property: hidden',
					'The operation was aborted.',
					'Blocked a frame with origin',
					'Unexpected EOF',
				];

				for (var i = 0; i < ms.length; i++) {
					let re = new RegExp(ms[i], 'i');

					if (
						error.message.match(re) ||
						(error.title && error.title.match(re))
					) {
						return null;
					}
				}
			}

			return event;
		},
		allowUrls: [/:\/\/clikpost\.com/],
		denyUrls: [
			/stage\.clikpost\.com/,
			/clikpost\.test/,
			// Google Adsense
			/pagead\/js/i,
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		],
	});
}

const config = {
	addons: [],

	dataDomain:
		typeof document === 'undefined'
			? ''
			: document.location.host === 'clikpost.test'
				? 'http://data.clikpost.test'
				: '',
};

export default config;
